import { LanguageDto } from "@api/language-dto";
import { MunicipalityDto } from "@api/municipality-dto";
import { PostalCodeDto } from "@api/postal-code-dto";
import { AxiosRequestConfig } from "axios";
import ApiRespGen, { ApiResponse } from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { CountryDto } from "@api/country-dto";
import { Tol2008TreeDto } from "@api/tol2008-tree-dto";
import { OrganizationCommunicationLanguageDto } from "@api/organization-communication-language-dto";

const controller = "public/publicForm/publicForm";

export default {
	form: {
		get: {
			async getClassificationInformationsSingleLevel<T>(
				table: string,
				translationTable: string,
				translationProperties: string[],
				search?: string
			) {
				const axiosReqConfig = {
					params: {
						tableName: table,
						translationsTableName: translationTable,
						translationKeys: translationProperties,
						search: search,
					},
				} as AxiosRequestConfig;

				const url = `${controller}/ClassificationInformationsSingleLevel`;

				try {
					const response = await publicFormApiAxios.get<T[]>(url, axiosReqConfig);
					return new ApiRespGen<T[]>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<T[]>().autoDetectFailure(ex);
				}
			},
			async languages(search?: string, page?: number): Promise<ApiResponse<LanguageDto[]>> {
				try {
					const axiosReqConfig = {
						params: {
							search,
							page,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<LanguageDto[]>(
						`${controller}/Search/Languages`,
						axiosReqConfig
					);
					return new ApiRespGen<LanguageDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<LanguageDto[]>().autoDetectFailure(ex);
				}
			},
			async allCommunicationLanguages(role?: number) {
				try {
					const axiosReqConfig = {
						params: {
							role,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<
						OrganizationCommunicationLanguageDto[]
					>(`${controller}/AllCommunicationLanguages`, axiosReqConfig);
					return new ApiRespGen<OrganizationCommunicationLanguageDto[]>().success(
						response.data
					);
				} catch (ex: unknown) {
					return new ApiRespGen<OrganizationCommunicationLanguageDto[]>().autoDetectFailure(
						ex
					);
				}
			},
			async searchCommunicationLanguages(search: string, role?: number) {
				try {
					const axiosReqConfig = {
						params: {
							search,
							role,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<
						OrganizationCommunicationLanguageDto[]
					>(`${controller}/Search/CommunicationLanguages`, axiosReqConfig);
					return new ApiRespGen<OrganizationCommunicationLanguageDto[]>().success(
						response.data
					);
				} catch (ex: unknown) {
					return new ApiRespGen<OrganizationCommunicationLanguageDto[]>().autoDetectFailure(
						ex
					);
				}
			},
			async languagesForSkills(search: string): Promise<ApiResponse<LanguageDto[]>> {
				try {
					const axiosReqConfig = {
						params: {
							search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<LanguageDto[]>(
						`${controller}/Search/LanguagesForSkills`,
						axiosReqConfig
					);
					return new ApiRespGen<LanguageDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<LanguageDto[]>().autoDetectFailure(ex);
				}
			},
			async postalCodes(search: string): Promise<ApiResponse<PostalCodeDto[]>> {
				try {
					const axiosReqConfig = {
						params: {
							search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<PostalCodeDto[]>(
						`${controller}/Search/PostalCodes`,
						axiosReqConfig
					);
					return new ApiRespGen<PostalCodeDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<PostalCodeDto[]>().autoDetectFailure(ex);
				}
			},
			async municipalities(search: string): Promise<ApiResponse<MunicipalityDto[]>> {
				try {
					const axiosReqConfig = {
						params: {
							search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<MunicipalityDto[]>(
						`${controller}/Search/Municipalities`,
						axiosReqConfig
					);
					return new ApiRespGen<MunicipalityDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<MunicipalityDto[]>().autoDetectFailure(ex);
				}
			},

			async municipality(cityName: string): Promise<ApiResponse<MunicipalityDto>> {
				try {
					const axiosReqConfig = {
						params: {
							cityName,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<MunicipalityDto>(
						`${controller}/Search/Municipality`,
						axiosReqConfig
					);
					return new ApiRespGen<MunicipalityDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<MunicipalityDto>().autoDetectFailure(ex);
				}
			},
			async postalCode(postalcode: string): Promise<ApiResponse<PostalCodeDto>> {
				try {
					const axiosReqConfig = {
						params: {
							postalcode,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<PostalCodeDto>(
						`${controller}/Search/PostalCode`,
						axiosReqConfig
					);
					return new ApiRespGen<PostalCodeDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<PostalCodeDto>().autoDetectFailure(ex);
				}
			},

			async postalCodeMunicipality(search: string): Promise<ApiResponse<MunicipalityDto>> {
				try {
					const axiosReqConfig = {
						params: {
							search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<MunicipalityDto>(
						`${controller}/Search/PostalCodeMunicipality`,
						axiosReqConfig
					);
					return new ApiRespGen<MunicipalityDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<MunicipalityDto>().autoDetectFailure(ex);
				}
			},

			async countriesSearch(search?: string, page?: number) {
				try {
					const axiosReqConfig = {
						params: {
							search,
							page,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<CountryDto[]>(
						`${controller}/Search/Countries`,
						axiosReqConfig
					);
					return new ApiRespGen<CountryDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CountryDto[]>().autoDetectFailure(ex);
				}
			},

			async country(id: number): Promise<ApiResponse<CountryDto>> {
				try {
					const response = await publicFormApiAxios.get<CountryDto>(
						`${controller}/Search/Country/${id}`
					);
					return new ApiRespGen<CountryDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CountryDto>().autoDetectFailure(ex);
				}
			},

			async searchTol2008ByCode(search: string) {
				try {
					const resp = await publicFormApiAxios.get<Tol2008TreeDto[]>(
						controller + "/tol2008ByCode/" + search
					);
					return new ApiRespGen<Tol2008TreeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto[]>().autoDetectFailure(ex);
				}
			},
			async searchTol2008ByName(search: string) {
				try {
					const resp = await publicFormApiAxios.get<Tol2008TreeDto[]>(
						controller + "/tol2008ByName/" + search
					);
					return new ApiRespGen<Tol2008TreeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto[]>().autoDetectFailure(ex);
				}
			},

			async tol2008ByRootCode(rootCode: string) {
				try {
					const config = { params: { rootCode: rootCode } } as AxiosRequestConfig;
					const resp = await publicFormApiAxios.get<Tol2008TreeDto[]>(
						controller + "/tol2008BottomLevelByRootCode",
						config
					);
					return new ApiRespGen<Tol2008TreeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto[]>().autoDetectFailure(ex);
				}
			},

			async tol2008ById(id: number) {
				try {
					const url = `${controller}/tol2008`;
					const config = { params: { id: id } } as AxiosRequestConfig;
					const resp = await publicFormApiAxios.get<Tol2008TreeDto>(url, config);
					return new ApiRespGen<Tol2008TreeDto>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto>().autoDetectFailure(ex);
				}
			},

			async tol2008TopLevel() {
				try {
					const url = `${controller}/tol2008TopLevel`;
					const config = {} as AxiosRequestConfig;
					const resp = await publicFormApiAxios.get<Tol2008TreeDto[]>(url, config);
					return new ApiRespGen<Tol2008TreeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto[]>().autoDetectFailure(ex);
				}
			},

			async tol2008ByParentId(parentId: number) {
				try {
					const url = `${controller}/tol2008ByParentId`;
					const config = { params: { parentId: parentId } } as AxiosRequestConfig;
					const resp = await publicFormApiAxios.get<Tol2008TreeDto[]>(url, config);
					return new ApiRespGen<Tol2008TreeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto[]>().autoDetectFailure(ex);
				}
			},
		},
	},
};
